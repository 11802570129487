<template>
  <div class="introduceLines">
    <div class="tabs">
      <el-tabs v-model="channelNo" type="card" @tab-click="handleClick">
        <el-tab-pane
          v-for="(item, index) in channelList"
          :key="index"
          :label="item.channelName"
          :name="item.channelNo"
        ></el-tab-pane>
      </el-tabs>
    </div>
    <div class="lines-box">
      <div class="box">
        <h3>新开路线</h3>
        <div class="lines">
          <div v-if="newLines.length" class="list">
            <div :key="index" v-for="(tag, index) in newLines" class="line">
              <el-tag
                closable
                :disable-transitions="false"
                @close="handleClose(tag.id)"
              >
                {{ tag.lineName }}
              </el-tag>
            </div>
            <el-button
              v-if="newLines.length < 4"
              class="button-new-tag"
              size="small"
              @click="showInput(1)"
              >+
            </el-button>
          </div>
          <div v-else class="empty">
            <p>添加新开线路，快速推广</p>
            <el-button type="primary" @click="showInput(1)"
              >添加线路
            </el-button>
          </div>
        </div>
      </div>
      <div class="box">
        <h3>热门线路</h3>
        <div class="lines">
          <div v-if="hotLines.length" class="list">
            <div :key="index" v-for="(tag, index) in hotLines" class="line">
              <el-tag
                closable
                :disable-transitions="false"
                @close="handleClose(tag.id)"
              >
                {{ tag.lineName }}
              </el-tag>
            </div>
            <el-button
              v-if="hotLines.length < 4"
              class="button-new-tag"
              size="small"
              @click="showInput(2)"
              >+
            </el-button>
          </div>
          <div v-else class="empty">
            <p>添加热门线路，便于乘客快速购票</p>
            <el-button type="primary" @click="showInput(2)"
              >添加线路
            </el-button>
          </div>
        </div>
      </div>
      <div class="box">
        <h3>机场专线</h3>
        <div class="lines">
          <div v-if="airLines.length" class="list">
            <div :key="index" v-for="(tag, index) in airLines" class="line">
              <el-tag
                closable
                :disable-transitions="false"
                @close="handleClose(tag.id)"
              >
                {{ tag.lineName }}
              </el-tag>
            </div>
            <el-button
              v-if="airLines.length < 4"
              class="button-new-tag"
              size="small"
              @click="showInput(3)"
              >+
            </el-button>
          </div>
          <div v-else class="empty">
            <p>添加热门线路，便于乘客快速购票</p>
            <el-button type="primary" @click="showInput(3)"
              >添加线路
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      v-if="show"
      :visible.sync="show"
      :close-on-click-modal="false"
      :title="title"
      width="400px"
      @close="cancelFun"
    >
      <el-form
        ref="form"
        :model="form"
        class="demo-form-inline"
        label-position="right"
        label-width="85px"
        style="margin-top: 20px"
      >
        <el-form-item
          label="线路："
          prop="lineCode"
          :rules="{ required: true, message: '请选择线路', trigger: 'blur' }"
        >
          <el-select v-model="form.lineCode" clearable filterable placeholder="请选择">
            <el-option
              v-for="item in routeList"
              :key="item.lineCode"
              :label="item.lineName"
              :disabled="item.disabled"
              :value="item.lineCode"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancelFun">取 消</el-button>
        <el-button size="small" type="primary" @click="confirmFun"
          >确 认</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  queryLineRecommendList,
  deleteLineRecommendById,
  saveLineRecommend,
  queryIntercityMainLinePageAPI,
  scheduleLineAssociationTableDataAPI,
} from "@/api/lib/api.js";
export default {
  data() {
    return {
      channelList: [],
      channelNo: "",
      newLines: [],
      hotLines: [],
      airLines: [],
      show: false,
      type: 1,
      title: "",
      form: {
        lineCode: "",
      },
      list: [],
      routeList: [],
    };
  },
  mounted() {
    this.getQueryChannelList();
    this.getRouteList();
  },
  methods: {
    getRouteList() {
      queryIntercityMainLinePageAPI({
        pageSize: 9999,
        currentPage: 1,
      }).then((res) => {
        if (res.code == "SUCCESS") {
          this.list = res.data.list;
        }
      });
    },
    getQueryChannelList() {
      const data = {
        currentPage: 1,
        pageSize: 10,
        loginName: sessionStorage.getItem("username"),
      };
      scheduleLineAssociationTableDataAPI(data).then((res) => {
        if (res.code == "SUCCESS") {
          const nos = res.data.list[0].bindChannelNos;
          const names = res.data.list[0].bindChannelNames;
          for (let i = 0; i < nos.length; i++) {
            this.channelList.push({
              channelNo: nos[i],
              channelName: names[i],
            });
          }
          this.channelNo = this.channelList[0].channelNo;
          this.renderData();
        }
      });
    },
    renderData() {
      queryLineRecommendList({ channelNo: this.channelNo }).then((res) => {
        if (res.code == "SUCCESS") {
          this.newLines = res.data.filter((i) => i.recommendMode == 1);
          this.hotLines = res.data.filter((i) => i.recommendMode == 2);
          this.airLines = res.data.filter((i) => i.recommendMode == 3);
        }
      });
    },
    handleClick(tab) {
      this.renderData();
    },
    handleClose(id) {
      this.$confirm("删除后，将不再推荐该线路。", "提示", {
        type: "warning",
      }).then(() => {
        deleteLineRecommendById({ id }).then((res) => {
          if (res.code == "SUCCESS") {
            this.renderData();
            this.$message.success("删除成功");
          }
        });
      });
    },
    showInput(type) {
      this.type = type;
      if (type == 1) this.title = "添加新开线路";
      else if (type == 2) this.title = "添加热门线路";
      else this.title = "添加机场专线";
      this.show = true;
      const arr = [...this.newLines, ...this.hotLines, ...this.airLines];
      this.$nextTick(() => {
        this.routeList = this.list
          .filter((e) => e.vendibilityChannelNo.includes(this.channelNo))
          .map((e) => {
            e.disabled = false;
            if (arr.find((i) => e.lineCode == i.lineCode)) {
              e.disabled = true;
            }
            return e;
          });
      });
    },
    cancelFun() {
      this.show = false;
      this.form.lineCode = "";
    },
    confirmFun() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const data = {
            lineCode: this.form.lineCode,
            recommendMode: this.type,
            channelNo: this.channelNo,
          };
          saveLineRecommend(data).then((res) => {
            if (res.code == "SUCCESS") {
              this.show = false;
              this.$message.success("添加成功");
              this.renderData();
              this.cancelFun();
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.introduceLines {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  display: flex;
  flex-direction: column;
  .tabs {
    flex-shrink: 0;
  }
  .lines-box {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    box-sizing: border-box;
    padding-top: 0;
    .box {
      display: flex;
      flex: 1;
      flex-direction: column;
      .lines {
        flex: 1;
        box-sizing: border-box;
        border: 1px solid rgba(211, 211, 211, 0.411);
        .list {
          height: 100%;
          padding: 20px;
          box-sizing: border-box;
          overflow: auto;
          display: flex;
          flex-wrap: wrap;
          .line {
            margin-bottom: 20px;
            margin-right: 20px;
            cursor: pointer;
          }
          .button-new-tag {
            height: 32px;
            line-height: 30px;
            padding-top: 0;
            padding-bottom: 0;
          }
        }
        .empty {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          p {
            margin-bottom: 10px;
            color: #7c7c7c;
          }
        }
      }
    }
  }
}
</style>
